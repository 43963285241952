<template>
  <div class="container">
    <nav-top
      :list="nav.list"
      :current-key="nav.currentKey"
      />

    <ul class="offer-list" ref="container">
      <template v-for="(item, itemKey) in nav.list">
        <template v-if="nav.currentKey === itemKey">
          <template v-if="!init || offerData[nav.currentKey].length">
            <template v-for="(row, key) in offerData[nav.currentKey]">
              <o-item :key="`${itemKey}:${key}`" :row="row" />
              <div class="ui-border-line" :key="`${itemKey}:${key}:line`"></div>
            </template>
          </template>
          <blank-search :key="itemKey" v-else text="받은 문의가 없습니다" />
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import OItem from '@/components/client/OItem'
import BlankSearch from '@/components/blank/BlankList'
import NavTop from '@/components/common/NavTop'

export default {
  name: 'ArtistDirectOffer',
  components: {
    OItem,
    BlankSearch,
    NavTop,
  },
  data() {
    let navBtnCb = (key = 0) => {
      this.que.offerData = this.que.offerData.map(() => false)
      this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
      this.nav.currentKey = key
      this.$nextTick()
        .then(() => {
          this.$refs.container.scrollTop = this.scroll.lastY[this.nav.currentKey]
          this.getOfferData(this.offerData[this.nav.currentKey].length === 0)
        })
    }
    return {
      init: false,
      offerData: {
        0: [],
        1: [],
        2: [],
      },
      offset: {
        offerData: [0, 0, 0],
      },
      que: {
        offerData: [false, false, false],
      },
      scroll: {
        lastY: [0, 0, 0],
      },
      nav: {
        list: [
          {
            label: '진행중',
            action: () => navBtnCb(0),
            nowState: 'ing,wait',
          },
          {
            label: '마감',
            action: () => navBtnCb(1),
            nowState: 'selfEnd,autoEnd',
          },
          {
            label: '결제완료',
            action: () => navBtnCb(2),
            nowState: 'end,cancel,cancelRequest,pay,payRequest',
          },
        ],
        currentKey: 0,
      },
      history: {
        exit: false,
      },
    }
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      }
    }
  },
  created() {
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/artist/direct-offer') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
          .then(() => {
            this.getOfferData(true)
          })
      }
    })

    this.getOfferData()
  },
  mounted() {
    this.$refs.container.addEventListener('scroll', this.getOfferDataScroll, false)
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      if (Vue.init) {
        Vue.$refs.container.scrollTop = Vue.scroll.lastY[Vue.nav.currentKey]
      } else {
        Vue.getOfferData(true)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.scroll.lastY[this.nav.currentKey] = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    getOfferData(init) {
      if (this.que.offerData[this.nav.currentKey]) return
      this.que.offerData[this.nav.currentKey] = true

      if (init) this.offset.offerData[this.nav.currentKey] = 0

      const req = {
        method: 'post',
        url: `/client/offer/${this.offset.offerData[this.nav.currentKey]}/10`,
        data: {
          artistidx: this.userData.artistidx,
          mode: 'direct',
          proPrice: 'y',
          nowState: this.nav.list[this.nav.currentKey].nowState,
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || []
            this.offerData[this.nav.currentKey] = init ? offerData : this.offerData[this.nav.currentKey].concat(offerData)
            this.offset.offerData[this.nav.currentKey] += offerData.length
          } else {
            this.$alert(resultData)
          }
          return
        })
        .catch(e => {
          log.error(e)
          return
        })
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.offerData[this.nav.currentKey] = false
          }, 1000)
          return
        })
    },
    getOfferDataScroll() {
      if (['ArtistDirectOffer'].indexOf(this.$route.name) === -1) return

      let scrollTop = this.$refs.container.scrollTop
      let scrollHeight = this.$refs.container.scrollHeight

      if (scrollHeight <= scrollTop + window.innerHeight) {
        this.getOfferData()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#page > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
  .offer-list {
    padding: 0 3rem;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
