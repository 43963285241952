var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("nav-top", {
        attrs: { list: _vm.nav.list, "current-key": _vm.nav.currentKey }
      }),
      _c(
        "ul",
        { ref: "container", staticClass: "offer-list" },
        [
          _vm._l(_vm.nav.list, function(item, itemKey) {
            return [
              _vm.nav.currentKey === itemKey
                ? [
                    !_vm.init || _vm.offerData[_vm.nav.currentKey].length
                      ? [
                          _vm._l(_vm.offerData[_vm.nav.currentKey], function(
                            row,
                            key
                          ) {
                            return [
                              _c("o-item", {
                                key: itemKey + ":" + key,
                                attrs: { row: row }
                              }),
                              _c("div", {
                                key: itemKey + ":" + key + ":line",
                                staticClass: "ui-border-line"
                              })
                            ]
                          })
                        ]
                      : _c("blank-search", {
                          key: itemKey,
                          attrs: { text: "받은 문의가 없습니다" }
                        })
                  ]
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }